import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import LayoutServices from "../../components/layout/layout-services"
import { ServicesStuntTeam } from "../../components/services/services-stunt-team"
import { Box, Container, Typography } from "@material-ui/core"

const StuntTeamPage = () => {
  const data = useStaticQuery(graphql`
    {
      strapi {
        servicesTeamPage {
          ServicesTeamPageTitle
          TeamMember {
            Name
            Perform1
            Perform2
            Perform3
            Sceen1
            Sceen2
            Sceen3
            Image {
              url
              imageFile {
                childImageSharp {
                  fixed(width: 250) {
                    ...GatsbyImageSharpFixed
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  return (
    <LayoutServices>
      <Box
        width="100%"
        display="flex"
        justifyContent="center"
        borderBottom="1px solid #F26522"
        px="16px"
      >
        <Container>
          <Box
            width="100%"
            paddingY={4}
            display="flex"
            style={{
              paddingTop: "150px",
              paddingLeft: "6%",
              maxWidth: "1200px",
              margin: "auto",
            }}
          >
            <Typography variant="h1">
              {data.strapi.servicesTeamPage.ServicesTeamPageTitle}
            </Typography>
          </Box>
        </Container>
      </Box>
      <ServicesStuntTeam team={data.strapi.servicesTeamPage} />
    </LayoutServices>
  )
}

export default StuntTeamPage
