import { Box } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import Img from "gatsby-image"
import React from "react"

const useStyles = makeStyles(theme => ({
  container: {
    paddingTop: "50px",
    maxWidth: "1200px",
    margin: "auto",
    [theme.breakpoints.down("sm")]: {},
  },
  members: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "start",
    paddingBottom: "50px",
    margin: "auto",
  },
  content: {
    maxWidth: "550px",
    margin: "20px",
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    flexDirection: "row",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      alignItems: "start",
    },
    [theme.breakpoints.down("xs")]: {
      maxWidth: "initial",
      width: "100%",
    },
  },
  intro: {
    display: "flex",
    justifyContent: "flex-start",
    flexDirection: "column",
    marginLeft: "20px",
    height: "100%",
    width: "250px",
    [theme.breakpoints.down("md")]: {
      marginLeft: "0px",
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  image: {
    width: "250px",
    height: "220px",
    marginBottom: "0px",
    [theme.breakpoints.down("md")]: {
      marginBottom: "10px",
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      height: "340px",
    },
  },
  link: {
    textDecoration: "none",
    textTransform: "uppercase",
    color: "#D5810F",
    fontSize: "16px",
    fontWeight: "700",
    "&:hover": {
      color: "#FFF",
    },
  },
  back: {
    textAlign: "left",
    marginBottom: "20px",
    paddingLeft: "6%",
    [theme.breakpoints.down("md")]: {
      paddingLeft: "10%",
    },
  },
}))

export const ServicesStuntTeam = ({ team }) => {
  const classes = useStyles()

  return (
    <Box className={classes.container}>
      <Box className={classes.members}>
        {team.TeamMember.map(
          ({
            Image,
            Name,
            Perform1,
            Perform2,
            Perform3,
            Sceen1,
            Sceen2,
            Sceen3,
          }) => (
            <Box key={Name} className={classes.content}>
              <Box className={classes.image}>
                <Img
                  alt={Name}
                  style={{ width: "100%", height: "100%" }}
                  fixed={Image.imageFile.childImageSharp.fixed}
                />
              </Box>
              <Box className={classes.intro}>
                <h3
                  variant="caption"
                  style={{
                    fontWeight: "700",
                    fontSize: "24px",
                    textTransform: "uppercase",
                    color: "#F26522",
                    margin: "0px",
                  }}
                >
                  {Name}
                </h3>
                <p
                  variant="caption"
                  style={{
                    fontWeight: "700",
                    textTransform: "uppercase",
                    color: "#F4F5F6",
                    margin: "0px",
                    paddingTop: "6px",
                  }}
                >
                  {Sceen1}
                </p>
                <p
                  variant="caption"
                  style={{
                    color: "#D4D7D9",
                    margin: "0px",
                  }}
                >
                  {Perform1}
                </p>
                <p
                  variant="caption"
                  style={{
                    fontWeight: "700",
                    textTransform: "uppercase",
                    color: "#F4F5F6",
                    margin: "0px",
                    paddingTop: "6px",
                  }}
                >
                  {Sceen2}
                </p>
                <p
                  variant="caption"
                  style={{
                    color: "#D4D7D9",
                    margin: "0px",
                  }}
                >
                  {Perform2}
                </p>
                <p
                  variant="caption"
                  style={{
                    fontWeight: "700",
                    textTransform: "uppercase",
                    color: "#F4F5F6",
                    margin: "0px",
                    paddingTop: "6px",
                  }}
                >
                  {Sceen3}
                </p>
                <p
                  variant="caption"
                  style={{
                    color: "#D4D7D9",
                    margin: "0px",
                  }}
                >
                  {Perform3}
                </p>
              </Box>
            </Box>
          )
        )}
      </Box>
    </Box>
  )
}
